import Highway from '@dogstudio/highway'
import Frontpage from '../../components/front'
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock'

const header = document.querySelector('.header')

class FrontTransition extends Highway.Transition {
  in({ from, to, _, done }) {
    window.scrollTo(0, 0)
    from.remove()

    enableBodyScroll(header)

    Frontpage.intro(to, done)
  }

  out({ from, _, done }) {
    document.body.classList.add('is-loading')

    Frontpage.outro(from, () => {
      disableBodyScroll(header, { reserveScrollBarGap: true })
      done()
    })
  }
}

export default FrontTransition

