import anime from 'animejs/lib/anime.es.js'

export default class Frontpage {
  constructor(el) {
    this.el = el
    this.init()
  }

  static intro(el, callback) {
    const entries = el.querySelectorAll('.entry a')

    if (entries.length) {
      anime({
        targets: entries,
        translateY: [150, 0],
        opacity: [0, 100],
        duration: 800,
        delay: anime.stagger(100, { start: 150 }),
        easing: 'easeOutCirc',
        begin: () => {
          document.body.classList.remove('is-hidden')
          document.body.classList.remove('is-loading')

          if (callback) {
            callback()
          }
        },
      })
    }
  }

  static outro(el, callback) {
    anime({
      targets: el,
      opacity: 0,
      easing: 'easeInOutCirc',
      duration: 400,
      complete: callback,
    })
  }

  init() {
    this.intro(this.el)
  }
}
