import anime from 'animejs/lib/anime.es.js'

export default class Page {
  constructor(el) {
    this.el = el
    this.init()
  }

  static intro(el, callback) {
    const content = el.querySelector('.content')

    const tl = anime.timeline({
      easing: 'easeInOutCirc',
    })

    tl.add({
      targets: el,
      opacity: [0, 1],
      duration: 600,
      begin: () => {
        document.body.classList.remove('is-hidden')
        document.body.classList.remove('is-loading')
      }
    })

    tl.add({
      targets: content,
      opacity: [0, 100],
      translateY: [100, 0],
      easing: 'easeOutCirc',
      duration: 800,
      complete: callback,
    }, 150)
  }

  static outro(el, callback) {
    anime({
      targets: el,
      opacity: 0,
      easing: 'easeInOutCirc',
      duration: 400,
      complete: callback,
    })
  }

  init() {
    this.intro(this.el)
  }
}
