import Highway from '@dogstudio/highway'
import DefaultRenderer from './router/default-renderer'
import DefaultTransition from './router/default-transition'
import FrontTransition from './router/front-transition'

class Router {
  constructor() {
    this.router = new Highway.Core({
      renderers: {
        default: DefaultRenderer,
        front: DefaultRenderer,
      },
      transitions: {
        default: DefaultTransition,
        front: FrontTransition,
      },
    })
  }

  on(type, func) {
    this.router.on(type, func)
  }

  attach(nodelist) {
    this.router.attach(nodelist)
  }
}

export default Router
