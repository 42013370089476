import "lazysizes"
import Router from './global/router'
import Frontpage from './components/front'
import Page from './components/page'

class Site {
  constructor() {
    this.router = undefined
    this.init()
  }

  initRouter() {
    this.router = new Router()
  }

  intro() {
    if (window.location.pathname === '/') {
      Frontpage.intro(document.body)
    } else {
      Page.intro(document.body)
    }
  }

  init() {
    this.initRouter()
    this.intro()
  }
}

if ('scrollRestoration' in history) {
  history.scrollRestoration = 'manual';
}

window.addEventListener('load', () => {
  window.scrollTo(0, 0)
  new Site()
})
