const loadComponents = (root) => {
  const masonryGrids = root.querySelectorAll('[data-masonry]')
  const entry = root.querySelector('[data-component="entry"]')
  const videos = root.querySelectorAll('[data-component="video"]')

  if (
    masonryGrids.length > 0 &&
    window.getComputedStyle(masonryGrids[0]).gridTemplateRows !== 'masonry'
  ) {
    import(/* webpackChunkName: "masonry" */ '../components/masonry').then(
      ({ default: Masonry }) => {
        Array.from(masonryGrids).forEach((grid) => {
          new Masonry(grid)
        })
      },
    )
  }

  if (entry !== null) {
    import(/* webpackChunkName: "entry" */ '../components/entry').then(
      ({ default: Entry }) => {
        new Entry(entry)
      }
    )
  }

  if (videos.length > 0) {
    import(/* webpackChunkName: "video" */ '../components/video').then(
      ({ default: VideoPlayer }) => {
        Array.from(videos).forEach((video) => {
          new VideoPlayer(video)
        })
      }
    )
  }
}

export default loadComponents
